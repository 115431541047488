import { render } from '@testing-library/react';
import React from 'react';
import { Count } from './Counter.types';

type IProps = {
    index: number;
    count: Count;
    onChange: (index: number, name: string, count: number) => void;
};

type IState = {};

function CountElement(props: IProps) {
    return (
        <div>
            <input
                type='text'
                value={props.count.name}
                onChange={(e) => {
                    props.onChange(
                        props.index,
                        e.target.value,
                        props.count.counter
                    );
                }}
            />
            <label>Count: {props.count.counter}</label>
            <button
                onClick={() => {
                    props.onChange(
                        props.index,
                        props.count.name,
                        props.count.counter + 1
                    );
                }}
            >
                Add one
            </button>
        </div>
    );
}

export default CountElement;
