import React from 'react';
import CountElement from './CountElement';
import { Count } from './Counter.types';

interface IProps {}
interface IState {
    elements: Array<Count>;
}

class CounterApp extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            elements: [],
        };
    }

    newElement(name: string, count: number) {
        let tempElements = this.state.elements.slice();
        console.log(tempElements.push({ name: name, counter: count }));
        console.log(this.state);
        this.setState({ elements: tempElements });
    }

    handleChange(index: number, name: string, count: number) {
        let tempElements = this.state.elements.slice();
        tempElements[index] = { name: name, counter: count };
        this.setState({ elements: tempElements });
    }

    exportToCSV() {
        let CSVtext = 'sep=;\nName;Count;\n';
        this.state.elements.map((value) => {
            CSVtext += `${value.name}; ${value.counter};\n`;
        });
        navigator.clipboard.writeText(CSVtext);
    }

    render() {
        return (
            <div>
                <div>
                    {this.state.elements.map((countElement, index) => (
                        <CountElement
                            key={index}
                            index={index}
                            count={countElement}
                            onChange={this.handleChange.bind(this)}
                        />
                    ))}
                </div>
                <button onClick={() => this.newElement('New Element', 0)}>
                    Add new
                </button>
                <button onClick={this.exportToCSV.bind(this)}>
                    Copy CSV to Clipboard
                </button>
            </div>
        );
    }
}

export default CounterApp;
